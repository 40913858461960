import React from "react";

import "./button-close.scss";

type ButtonCloseProps = {
  onClick: () => void;
};

const ButtonClose = ({ onClick }: ButtonCloseProps) => {
  return (
    <div onClick={onClick} className="button-close">
      <img src="../img/icns/close.png" alt="Close button" width="20" />
    </div>
  );
};

export default ButtonClose;
