import { Outlet } from "react-router-dom";
import { ReactElement } from "react";
import { useAppSelector } from "../../hooks/hooks";
import {
  selectIsAuthenticated,
  selectUser,
} from "../../auth/data-access/store/authSlice";

type Role = "user" | "guest" | "userToken";

interface PrivateRouteProps {
  grantedRoles: Array<Role>;
  onDenied: () => ReactElement;
  onIncomplete: false | (() => ReactElement);
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { grantedRoles, onDenied, onIncomplete } = props;

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const user = useAppSelector(selectUser);
  const subscriptionStatus = user?.eligibility.subscriptionStatus;

  const clientRole: Role = isAuthenticated
    ? user?.eligibility.authentication === "manual"
      ? "user"
      : "userToken"
    : "guest";

  if (subscriptionStatus === "INACTIVE" && onIncomplete) {
    return onIncomplete();
  } else if (subscriptionStatus === "INACTIVE" && !onIncomplete) {
    return <Outlet />;
  }

  return grantedRoles.includes(clientRole) ? <Outlet /> : onDenied();
};

export default PrivateRoute;
