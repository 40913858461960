import "./background.scss";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { Categories } from "../../data-access/store/game/types/socket-events/category";
import { useEffect, useState } from "react";

export interface BackgroundProps {
  children: Array<JSX.Element> | JSX.Element | string;
  type: "dark" | "defaultGame" | Categories;
  category?: string;
}

export const BackgroundType: {
  [Key in BackgroundProps["type"]]: Key;
} = {
  dark: "dark",
  defaultGame: "defaultGame",
  general_knowledge: "general_knowledge",
  geography: "geography",
  sports: "sports",
  history: "history",
  music: "music",
  movies: "movies",
  lifestyle: "lifestyle",
  celebrity: "celebrity",
  religion: "religion",
  home: "home",
  onboarding: "onboarding",
  opponent: "opponent",
};

// /**
//  * This is a dynamic background selector, where the
//  * developer can update the background based on the
//  * categories set in the design.
//  *
//  * Example:
//  * ```tsx
//  * <Background type="home">
//  *   <div>Page content goes here.</div>
//  * </Background>
//  * ```
//  * @category Component
//  */
export function Background({ children, type, category }: BackgroundProps) {
  const [backgroundVariants, setBackgroundVariants] = useState<Variants>({
    [BackgroundType.dark]: {
      background: "transparent",
    },
    [BackgroundType.defaultGame]: {
      background: " linear-gradient(180deg, #B165D6 0%, #9D3ECC 100%)",
    },
    [BackgroundType.music]: {
      background: "linear-gradient(180deg, #D471CE 0%, #94478F 100%)",
    },
    [BackgroundType.general_knowledge]: {
      background: "linear-gradient(180deg, #56D598 0%, #27AA6B 100%)",
    },
    [BackgroundType.geography]: {
      background: "linear-gradient(180deg, #A7A3FE 0%, #726EC7 100%)",
    },
    [BackgroundType.movies]: {
      background: "linear-gradient(180deg, #9D6AEF 0%, #734CB0 100%)",
    },
    [BackgroundType.lifestyle]: {
      background: "linear-gradient(180deg, #30C3BC 0%, #27A29C 100%)",
    },
    [BackgroundType.celebrity]: {
      background: "linear-gradient(180deg, #FF952F 0%, #B6691F 100%)",
    },
    [BackgroundType.religion]: {
      background: "linear-gradient(180deg, #D5A165 0%, #A97F4E 100%)",
    },
    [BackgroundType.home]: {
      background: "linear-gradient(0deg, #E6E6E6, #E6E6E6)",
    },
    [BackgroundType.onboarding]: {
      background: "linear-gradient(0deg, #E6E6E6, #E6E6E6)",
    },
    [BackgroundType.opponent]: {
      background: "linear-gradient(180deg, #9383E5 0%, #705BDD 100%)",
    },
    [BackgroundType.sports]: {
      background: "linear-gradient(180deg, #FF5383 0%, #A43453 100%)",
    },
    [BackgroundType.history]: {
      background: "linear-gradient(180deg, #4FB1FF 0%, #3477AC 100%)",
    },
  });

  const backgroundValues = Object.values(BackgroundType);
  const animateValues = backgroundValues.map((value) => {
    if (type === value) {
      return value;
    } else {
      return "";
    }
  });

  useEffect(() => {
    if (category) {
      setBackgroundVariants({
        ...backgroundVariants,
        categoryGradient: {
          background: category,
        },
      });
    }
  }, [category]);

  return (
    <div className="background-wrapper">
      <AnimatePresence>
        {type && (
          <>
            {type === "dark" && <div className="background-dark-overlay"></div>}
            <motion.div
              id="background"
              className={type}
              variants={backgroundVariants}
              initial={BackgroundType.defaultGame}
              animate={category ? "categoryGradient" : animateValues}
            >
              <div className="background-square"></div>
              <div className="background-white-blur"></div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
      <div className="content">{children}</div>
    </div>
  );
}

export default Background;
