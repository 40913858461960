import { ComponentProps } from "react";
import { Avatar } from "../../../shared/ui/avatar";
import "./leader-step.scss";
import cx from "classnames";

export interface LeaderStepProps extends ComponentProps<"div"> {
  avatar: string;
  position: number;
  numberDiamonds: number;
  username: string | undefined;
  type?: "success" | "gold";
  sticky?: "bottom" | "top";
}

export function LeaderStep({
  avatar,
  position,
  numberDiamonds,
  username,
  className,
  type,
  sticky, //add user-leaderboard-position class
  ...props
}: LeaderStepProps) {
  return (
    <div
      className={cx(
        "leaderboard-row user-list",
        `leaderboard-row-${type}`,
        className,
        {
          "user-leaderboard-position": sticky === "bottom",
        },
        {
          "user-leaderboard-champion": sticky === "top",
        }
      )}
      {...props}
    >
      <div className="slot slot-left">
        <div className="leaderboard-player-position-number">{position}</div>
        <Avatar image={avatar} />
      </div>
      <div className="slot slot-right">
        <div>
          <p>{username}</p>
          <div className="points">{numberDiamonds}</div>
        </div>
        <img src="/img/icns/diamonds.png" alt="Diamond icon" />
      </div>
    </div>
  );
}

export default LeaderStep;
