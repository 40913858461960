import InfiniteScroll from "react-infinite-scroller";
import { Background } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import { Title } from "../../../shared/ui/title";
import { LeaderStep } from "../../ui/leader-step";
import "./leaderboard-view.scss";
import { useEffect, useState } from "react";
import { useGetLeaderboardQuery } from "../../../auth/data-access/store/services/auth.service";
import { Leaderboard } from "../../../shared/interfaces/auth.types";
import { useAppSelector } from "../../../hooks/hooks";
import { selectUser } from "../../../auth/data-access/store/authSlice";
import { motion, Variants } from "framer-motion";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import useAmbientSound from "../../../utils/hooks/use-ambient-sound";

const leaderboardListVariants: Variants = {
  hidden: {
    y: -10,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.05 * custom,
    },
  }),
};

export function LeaderboardView() {
  const limit = 20;
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [leaderboardCurrent, setLeaderboardCurrent] = useState<
    Leaderboard["items"] | []
  >([]);
  const {
    data: leaderboardData,
    isLoading: leaderboardIsLoading,
    isFetching,
  } = useGetLeaderboardQuery({ limit, offset });
  const user = useAppSelector(selectUser);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  useAmbientSound("home");

  useEffect(() => {
    if (leaderboardData) {
      setLeaderboardCurrent([...leaderboardCurrent, ...leaderboardData.items]);
    }
    if (leaderboardData?.items.length === 0) {
      setHasMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaderboardData]);

  const loadMore = () => {
    if (!isFetching) {
      setOffset(offset + limit);
    }
  };

  const renderLeaderboardItems = (leaderboardItems: Leaderboard["items"]) => {
    return leaderboardItems?.map((item, index) => {
      let sticky: "top" | "bottom" | undefined;
      let type: "success" | "gold" | undefined;
      if (item.rank === 1) {
        sticky = "top";
        type = "gold";
      }

      if (user && user.character?.id === item.id) {
        type = "success";
      }
      return (
        <motion.li
          key={item.id}
          variants={leaderboardListVariants}
          initial="hidden"
          animate="visible"
          // @ts-ignore
          custom={index}
        >
          <LeaderStep
            type={type}
            avatar={item.avatar}
            numberDiamonds={item.points}
            username={item.nickname}
            position={item.rank}
            sticky={sticky}
          ></LeaderStep>
        </motion.li>
      );
    });
  };

  if (leaderboardIsLoading) {
    return null;
  }

  return (
    <>
      <Background type="geography">
        <NavBar></NavBar>
        <Title>
          {checkTranslationKey(translations[lang]?.Leaderboard, "Leaderboard")}
        </Title>
        <div className="leaderboard-container">
          <InfiniteScroll
            element="ul"
            className="leaderboard-scrolling"
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            useWindow={false}
            initialLoad={false}
            threshold={10}
          >
            {leaderboardData
              ? renderLeaderboardItems(leaderboardCurrent)
              : null}
          </InfiniteScroll>

          <LeaderStep
            avatar={user?.character?.avatar || ""}
            numberDiamonds={user?.character?.points || 0}
            position={user?.character?.position || 0}
            username={user?.character?.nickname}
            type="success"
            sticky="bottom"
          ></LeaderStep>
        </div>
      </Background>
    </>
  );
}

export default LeaderboardView;
