import { Background } from "../../../shared/ui/background";
import { NavBar } from "../../../shared/ui/navbar";
import { Title } from "../../../shared/ui/title";
import { PrizeCard } from "../../ui/prize-card";
import "./prizes-view.scss";
import { useGetPrizesQuery } from "../../data-access/store/services/prizes.service";
import { Prizes } from "../../../shared/interfaces/prize.types";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import { motion, Variants } from "framer-motion";
import useAmbientSound from "../../../utils/hooks/use-ambient-sound";

const prizesVariants: Variants = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.2 * (custom + 1),
    },
  }),
};

export function PrizesView() {
  const { data: prizesData } = useGetPrizesQuery();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  useAmbientSound("home");

  const renderPrizes = (prizes: Prizes["items"]) => {
    return prizes?.map((prize, index) => {
      return (
        <motion.div
          key={prize.id}
          variants={prizesVariants}
          initial="hidden"
          animate="visible"
          custom={index}
          className="prize-wrapper"
        >
          <PrizeCard
            type={prize.position === 1 ? "large" : "small"}
            image={
              prize.position === 1 ? "/img/prize/grand-prize.png" : prize.image
            }
            title={prize.name[lang]}
            subtitle={prize.description ? prize.description[lang] : ""}
          ></PrizeCard>
        </motion.div>
      );
    });
  };

  return (
    <div className="prizes-container">
      <Background type="geography">
        <NavBar hasAvatar />
        <div className="prizes-content">
          <Title>
            {checkTranslationKey(translations[lang]?.Prizes, "Prizes")}
          </Title>
          <div className="prizes-items">
            {prizesData && prizesData.items
              ? renderPrizes(prizesData.items)
              : null}
          </div>
        </div>
      </Background>
    </div>
  );
}

export default PrizesView;
