import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGameEvents } from "../../../shared/data-access/store/game/hooks/use-game-events";
import { Background } from "../../../shared/ui/background";
import { Modal } from "../../../shared/ui/modal";
import { NavBar } from "../../../shared/ui/navbar";
import { GenieModal } from "../../ui/ask-genie";
import { GameplayAnswer } from "../../ui/gameplay-answer";
import { GameplayLayout } from "../../ui/gameplay-layout";
import { Hints } from "../../ui/hints";
import { PrizeQuestion } from "../../ui/question-prize";
import "./gameplay-guess-it.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { assertCorrectAnswer } from "../../utils/evaluate-game-answer";
import cx from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useCountdown } from "../../../utils/hooks/use-countdown";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { SkillTypes } from "../../../shared/data-access/store/game/types/game-types";
import { Badge } from "../../../shared/ui/badge";
import { useLazyGetUserQuery } from "../../../auth/data-access/store/services/auth.service";
import { bonusResultContainerVariants } from "../../utils/animation-states";
import { setMessage } from "../../../shared/data-access/store/message/messageSlice";
import { UseSkillResponse } from "../../../shared/data-access/store/game/types/socket-events/use-skill";
import { differenceInSeconds } from "date-fns";
import {
  GINNIE_ANIMATION_DELAY,
  TIMER_ANIMATION_DELAY,
  SKIP_ANIMATION_DELAY,
} from "../../../utils/constants";
import shuffleAnswers from "../../utils/shuffle-answers";
import SoundManager from "../../../utils/managers/sound-manager";
import { checkTranslationKey } from "../../../utils/translation";

const optionsVariants: Variants = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  visible: (custom) => ({
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.1,
      delay: 0.1 * (custom + 1),
    },
  }),
};

const lifeVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 3,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 2,
    },
  },
};

export function GameplayGuessIt() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const translations = useAppSelector(selectTranslations);

  const gameType: "classic" | "bonus" = useMemo(
    () => (location.pathname.includes("bonus") ? "bonus" : "classic"),
    [location.pathname]
  );
  const [showGenie, setShowGenie] = useState(false);
  const [answered, setAnswered] = useState(false);
  const hasUsedSkip = useRef<boolean>(false);

  const { data, meta, game, gameLoaded, send, resetState } =
    useGameEvents("start_round");
  const {
    send: updateAnswer,
    data: results,
    resetState: resetAnswer,
    error: answerError,
  } = useGameEvents("update_round_answer");
  const { send: sendSkill, resetState: resetSkillData } =
    useGameEvents("use_skill");
  const { send: replenish } = useGameEvents("replenish_skill");
  const { data: categoryData, send: getCategory } =
    useGameEvents("get_category");
  const { send: finishGame } = useGameEvents("finish_game");

  const [getUser] = useLazyGetUserQuery();

  const [skillResult, setSkillResult] = useState<
    Pick<UseSkillResponse, "skill_result">["skill_result"]
  >({});

  const question = useMemo(() => {
    if (skillResult.new_question) {
      hasUsedSkip.current = true;
    }

    const nextQuestion = skillResult.new_question || data?.data?.question;
    if (nextQuestion) {
      return shuffleAnswers(nextQuestion);
    }
  }, [data?.data?.question, skillResult.new_question]);
  const timer = question?.timer;
  const lang = useAppSelector(selectLanguage);
  const { uuid, token } = meta;
  const points = useMemo(
    () => (game ? game.ladder_points[game.current_round_index].value : 0),
    [gameLoaded]
  );
  const navigate = useNavigate();
  const replanishingLockRef = useRef(false);
  const finished = game?.finished;
  const currentTimeRef = useRef<number>();
  const startGennieDateRef = useRef<Date>();

  const {
    timeLeft,
    start,
    stop,
    clean,
    add: addTime,
    reload: reloadTimer,
  } = useCountdown(
    timer &&
      timer +
        (hasUsedSkip.current ? SKIP_ANIMATION_DELAY : TIMER_ANIMATION_DELAY)
  );

  const isImageQuestion = () =>
    question && question.answers[0].type === "image";

  const goToLadder = useCallback(
    (shouldFinish: boolean) => {
      resetState();
      resetAnswer();
      resetSkillData();
      navigate("/ladder", { state: { shouldFinish } });
    },
    [navigate, resetAnswer, resetState, resetSkillData]
  );
  const commonParams = useMemo(() => {
    if (!game?.id) {
      return false;
    }
    return {
      playedGame: {
        id: game.id,
      },
      userToken: token,
      uuid,
    };
  }, [game?.id, token, uuid]);

  const correctAnswer = assertCorrectAnswer(results, game);

  const handleAnswer = useCallback(
    async (index: number) => {
      if (!commonParams || currentTimeRef.current === undefined || answered) {
        return;
      }

      setAnswered(true);

      stop();
      clean();
      try {
        await updateAnswer({
          ...commonParams,
          answer: index,
          countdown: currentTimeRef.current,
          playedGame: {
            ...commonParams.playedGame,
            current_round_index: game!.current_round_index,
          },
        });
      } catch (e) {
        dispatch(
          setMessage({
            content: "Oops, this game expired.",
            type: "error",
          })
        );
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commonParams, game, updateAnswer, goToLadder, stop]
  );

  useEffect(() => {
    if (results) {
      if (results?.data.round.correct) {
        SoundManager.vfxSounds.correct.play();
      } else if (!results?.data.round.correct) {
        SoundManager.vfxSounds.wrong.play();
      }
    }
  }, [results]);

  const handleSkillCallback = (skill: SkillTypes) => {
    return async () => {
      if (!game || !token || !uuid || answered) {
        return;
      }
      const skillData = await sendSkill({
        playedGame: {
          id: game.id,
          current_round_index: game.current_round_index,
        },
        userToken: token,
        uuid,
        skill_code: skill,
      });

      const result = skillData.data.skill_result;
      if (result) {
        if (result.new_question) {
          setSkillResult(result);
        } else {
          setSkillResult((prev) => ({ ...result, ...prev }));
        }
      }

      if (skill === "skip") {
        SoundManager.vfxSounds.skip.play();
        reloadTimer();
      }
      if (skill === "ask_genie") {
        SoundManager.vfxSounds.genie.play();
        startGennieDateRef.current = new Date();
        stop();
        setShowGenie(true);
      }
      if (skill === "hide") {
        SoundManager.vfxSounds.hide.play();
      }
    };
  };

  const handleReplenishCallback = (skill: SkillTypes) => {
    return async () => {
      if (!game || !token || !uuid || answered || replanishingLockRef.current) {
        return;
      }

      replanishingLockRef.current = true;
      await replenish({
        playedGame: {
          id: game.id,
          current_round_index: game.current_round_index,
        },
        userToken: token,
        uuid,
        skill_code: skill,
      });
      await getUser().unwrap();
      replanishingLockRef.current = false;
      SoundManager.vfxSounds.life.play();
    };
  };

  useEffect(() => {
    return () => {
      getUser();
    };
  }, []);

  useEffect(() => {
    if (
      answerError?.error_header === 400 &&
      answerError?.error?.error?.includes("synchroniz") &&
      commonParams
    ) {
      finishGame({ ...commonParams, force_finish: true });
    }
  }, [answerError, commonParams]);

  useEffect(() => {
    if (timeLeft === undefined) {
      return;
    }

    if (timeLeft < 10) {
      SoundManager.vfxSounds.clock.playbackRate = 1.2;
    }

    if (timeLeft > 0) {
      currentTimeRef.current = timeLeft;
      SoundManager.vfxSounds.clock.play();
    } else {
      if (timeLeft === 0) {
        if (currentTimeRef.current && currentTimeRef.current > 0) {
          currentTimeRef.current = 0;
        }
      } else {
        if (!currentTimeRef.current) {
          currentTimeRef.current = 0;
        }
        stop();
        handleAnswer(-1);
      }
    }
  }, [timeLeft]);

  useEffect(() => {
    if (showGenie) {
      const timeout = setTimeout(() => {
        addTime(8 + GINNIE_ANIMATION_DELAY);
        setShowGenie(false);
      }, 8000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showGenie]);

  useEffect(() => {
    if (!question) {
      stop();
    } else {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  useEffect(() => {
    if (gameLoaded && commonParams) {
      if (finished) {
        setTimeout(() => {
          goToLadder(finished);
        }, 3000);
        //should reload game
      } else {
        if (!data) {
          send(commonParams);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameLoaded]);

  useEffect(() => {
    if (commonParams && results?.data) {
      setTimeout(() => {
        goToLadder(results.data.finished);
      }, 3000);
    }
  }, [results, commonParams]);

  useEffect(() => {
    if (commonParams && !categoryData?.data.id) {
      getCategory(commonParams);
    }
  }, [commonParams, categoryData?.data.id]);

  useEffect(() => {
    if (answered) {
      SoundManager.vfxSounds.clock.pause();
      SoundManager.vfxSounds.clock.playbackRate = 1;
      SoundManager.vfxSounds.clock.currentTime = 0;
    }
  }, [answered]);

  if (!categoryData?.data) {
    return null;
  }

  return (
    <div className="gameplay-page">
      <Background
        type={
          categoryData && categoryData.data?.category?.code
            ? categoryData.data.category?.code
            : "defaultGame"
        }
        category={categoryData.data.category?.gradient_background}
      >
        <NavBar hideMenu slotEnd="lives" />
        <GameplayLayout
          timer={currentTimeRef.current}
          prizes={
            <PrizeQuestion
              icon="diamonds"
              category={categoryData?.data?.category?.icon || ""}
            >
              {points}
            </PrizeQuestion>
          }
          question={
            gameLoaded
              ? (question?.content[lang] || question?.content.en) ?? ""
              : "Loading question..."
          }
          hint={
            gameType === "classic" ? (
              <>
                <Hints
                  title="Hide"
                  icon="bomb"
                  disabled={!game?.skills.items.hide.available}
                  onClick={handleSkillCallback("hide")}
                  bottom={
                    <AnimatePresence>
                      {!game?.skills.items.hide.available ? (
                        <Badge
                          type="success"
                          aspect="rectangle"
                          onClick={handleReplenishCallback("hide")}
                        >
                          <>
                            <motion.img
                              variants={lifeVariants}
                              animate={"visible"}
                              exit={"hidden"}
                              src="/img/icns/lives.svg"
                              alt=""
                            />
                            1
                          </>
                        </Badge>
                      ) : undefined}
                    </AnimatePresence>
                  }
                ></Hints>
                <Hints
                  title="Ask Genie"
                  icon="genie"
                  disabled={!game?.skills.items.ask_genie.available}
                  onClick={handleSkillCallback("ask_genie")}
                  bottom={
                    <AnimatePresence>
                      {!game?.skills.items.ask_genie.available ? (
                        <Badge
                          type="success"
                          aspect="rectangle"
                          onClick={handleReplenishCallback("ask_genie")}
                        >
                          <>
                            <motion.img
                              variants={lifeVariants}
                              animate={"visible"}
                              exit={"hidden"}
                              src="/img/icns/lives.svg"
                              alt=""
                            />
                            1
                          </>
                        </Badge>
                      ) : undefined}
                    </AnimatePresence>
                  }
                ></Hints>
                <Hints
                  title="Skip"
                  icon="skip"
                  disabled={!game?.skills.items.skip.available}
                  onClick={handleSkillCallback("skip")}
                  bottom={
                    <AnimatePresence>
                      {!game?.skills.items.skip.available ? (
                        <Badge
                          type="success"
                          aspect="rectangle"
                          onClick={handleReplenishCallback("skip")}
                        >
                          <>
                            <motion.img
                              variants={lifeVariants}
                              animate={"visible"}
                              exit={"hidden"}
                              src="/img/icns/lives.svg"
                              alt=""
                            />
                            1
                          </>
                        </Badge>
                      ) : undefined}
                    </AnimatePresence>
                  }
                ></Hints>
              </>
            ) : undefined
          }
        >
          {/* If the options are type image add class .options-images on the .options div */}
          <div
            className={cx("options", {
              "options-images": isImageQuestion(),
            })}
          >
            {question?.answers
              ?.filter((answer) => {
                if (!skillResult.hide_answer_indexes) {
                  return true;
                }
                return !skillResult.hide_answer_indexes.includes(answer.index);
              })
              .map((answer) => {
                const index = answer.index;
                const key = `${answer.type}-${index}`;
                const status = correctAnswer
                  ? (cx({
                      correct: correctAnswer.correct_answer_index === index,
                      wrong:
                        correctAnswer.correct_answer_index !== index &&
                        correctAnswer.answer_index === index,
                      default:
                        correctAnswer.correct_answer_index !== index &&
                        correctAnswer.answer_index !== index,
                    }) as "correct" | "wrong" | "default")
                  : "default";
                if (answer.type === "text") {
                  return (
                    <motion.div
                      key={key}
                      className="answer-wrapper"
                      variants={optionsVariants}
                      initial="hidden"
                      animate="visible"
                      custom={index}
                    >
                      <GameplayAnswer
                        gameplay="text"
                        status={status}
                        options={{
                          text: answer.value[lang] || answer.value.en,
                          badgeType: gameType === "bonus" ? "outer" : "inner",
                        }}
                        onClick={() => handleAnswer(index)}
                        disabled={!!correctAnswer}
                      />
                    </motion.div>
                  );
                } else if (answer.type === "image") {
                  return (
                    <motion.div
                      key={key}
                      className="answer-wrapper"
                      variants={optionsVariants}
                      initial="hidden"
                      animate="visible"
                      custom={index}
                    >
                      {!answer.show_label ? (
                        <GameplayAnswer
                          gameplay="image"
                          status={status}
                          options={{
                            imageSrc: answer.value,
                          }}
                          onClick={() => handleAnswer(index)}
                          disabled={!!correctAnswer}
                        />
                      ) : (
                        <GameplayAnswer
                          gameplay="image-with-text"
                          status={status}
                          options={{
                            imageSrc: answer.value,
                            text: answer.label
                              ? answer.label[lang] || answer.label.en
                              : "",
                          }}
                          onClick={() => handleAnswer(index)}
                          disabled={!!correctAnswer}
                        />
                      )}
                    </motion.div>
                  );
                }
              })}
          </div>
        </GameplayLayout>
      </Background>
      <AnimatePresence>
        {showGenie && (
          <motion.div
            variants={bonusResultContainerVariants}
            initial={"hidden"}
            animate={"visible"}
            exit={"hidden"}
            className="modal-presence-wrapper"
          >
            <Modal
              show={showGenie}
              setShow={setShowGenie}
              hideCloseButton
              defaultAnimations={false}
              disableClickOutside
            >
              <GenieModal
                introText={checkTranslationKey(
                  translations[lang]?.Genie_popup_text1,
                  "I don’t want to brag but I can't help it... This is a piece of cake for me! My guess would be:"
                )}
                answer={question?.answers.find(
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  (answer) => answer.index === skillResult.correct_answer_index!
                )}
                onClick={() => {
                  setShowGenie(false);
                  const gennieStartDate = startGennieDateRef.current;
                  if (gennieStartDate) {
                    addTime(
                      differenceInSeconds(new Date(), gennieStartDate) +
                        GINNIE_ANIMATION_DELAY
                    );
                  }
                }}
                genieQuestion={checkTranslationKey(
                  translations[lang]?.Genie_popup_text2,
                  "Is this the hardest it can be?"
                )}
                genie={game?.genie ?? "male"}
              />
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default GameplayGuessIt;
