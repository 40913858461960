import "./prize-to-win.scss";
import { useAppSelector } from "../../../hooks/hooks";
import {
  selectLanguage,
  selectTranslations,
} from "../../data-access/store/general/generalSlice";
import { useGetPrizesQuery } from "../../../prizes/data-access/store/services/prizes.service";
import { checkTranslationKey } from "../../../utils/translation";

export function PrizeToWin() {
  const { data: prizesData } = useGetPrizesQuery();
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);

  const grandPrize = prizesData?.items?.find((prize) => prize.position === 1);

  return (
    <div id="prizes">
      {grandPrize && (
        <div className="prize">
          <div className="prize-image">
            <img src={grandPrize.image} alt="" />
          </div>
          {grandPrize.caption && (
            <div className="prize-name">{grandPrize.caption[lang]}</div>
          )}
        </div>
      )}
      <div className="prize-title">
        {checkTranslationKey(translations[lang]?.Prizes, "Prizes")}
        <div className="small-title">
          {checkTranslationKey(translations[lang]?.to_win, "to win!")}
        </div>
      </div>
      <div className="prize">
        <div className="prize-image">
          <img src="../img/prize/instant.png" alt="" />
        </div>
        <div className="prize-name">
          {/*{checkTranslationKey(translations[lang]?.Strike_win, "Strike+Win")}*/}
        </div>
      </div>
    </div>
  );
}
