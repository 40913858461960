import {
  motion,
  HTMLMotionProps,
  Variants,
  AnimatePresence,
} from "framer-motion";
import "./button.scss";
import cx from "classnames";

export interface ButtonProps extends HTMLMotionProps<"button"> {
  /**
   * The button type
   * @default primary
   */
  buttonType?:
    | "primary"
    | "success"
    | "success-gold"
    | "white"
    | "red"
    | "ladder-white"
    | "ladder-yellow"
    | "transparent"
    | "gold";
  disabled?: boolean;
  animation?: boolean;
  animationSettings?: {
    delay?: number;
  };
  refCallback?: (el: HTMLElement | null) => void;
  small?: boolean;
}

/**
 * Button component
 *
 * The usage:
 *
 *  ```tsx
 *  import { Button } from '<path-to-shared>/shared/ui/button';
 *  <Button buttonType="primary">Button</Button>
 *  ```
 *
 * @param props Button props for the button component.
 * @returns
 */

export function Button({
  children,
  buttonType,
  disabled,
  animation,
  animationSettings,
  refCallback,
  small,
  ...props
}: ButtonProps) {
  const variantsButton: Variants = {
    normal: {
      opacity: 1,
      background: "#F4F4F4",
      border: "2px solid #9F9F9F",
      color: "#000",
      transition: {
        delay: 0,
      },
    },
    pending: {
      // opacity: 1,
      // background: "#FFE1BF",
    },
    success: {
      // opacity: 1,
      // border: "2px solid transparent",
      // boxShadow: "inset 0px -6px 0px rgba(0, 0, 0, 0.25)",
      // background: "#FFE1BF",
      // transition: {
      //   duration: 0.3,
      //   delay:
      //     animationSettings && animationSettings.delay
      //       ? animationSettings.delay
      //       : 0,
      // },
    },
    gold: {
      // height: 60,
      // transition: {
      //   duration: 0,
      // },
    },
    "success-gold": {
      // height: 60,
      // transition: {
      //   duration: 0.3,
      //   delay:
      //     animationSettings && animationSettings.delay
      //       ? animationSettings.delay
      //       : 0,
      // },
    },
    disabled: {
      // opacity: 0.2,
      // transition: {
      //   duration: 0.3,
      // },
    },
  };

  const variantsButtonBg = {
    hidden: {
      opacity: 0,
    },
    success: {
      opacity: 1,
      transition: {
        duration: 0.3,
        delay:
          animationSettings && animationSettings.delay
            ? animationSettings.delay
            : 0,
      },
    },
    gold: {
      opacity: 1,
      transition: {
        duration: 0,
      },
    },
  };

  return (
    <motion.button
      {...props}
      disabled={disabled}
      ref={refCallback ? (el) => refCallback(el) : null}
      initial={false}
      variants={variantsButton}
      // animate={[
      //   animation && (buttonType === "success" || buttonType === "success-gold")
      //     ? "success"
      //     : "",
      //   animation && buttonType === "ladder-white" ? "normal" : "",
      //   animation && buttonType === "ladder-yellow" ? "pending" : "",
      //   animation && buttonType === "gold" ? "gold" : "",
      //   animation && disabled ? "disabled" : "",
      //   animation && buttonType === "success-gold" ? "success-gold" : "",
      // ]}
      className={`button button-${buttonType || "primary"} ${
        props.className ?? ""
      }`}
    >
      <AnimatePresence>
        {(buttonType === "success" ||
          buttonType === "success-gold" ||
          buttonType === "gold") && (
          <motion.div
            className={cx(
              "bg-to-animate",
              animation &&
                (buttonType === "success" || buttonType === "success-gold")
                ? "button-success"
                : "",
              animation && buttonType === "gold" ? "button-gold" : ""
            )}
            variants={variantsButtonBg}
            initial="hidden"
            animate={[
              animation &&
              (buttonType === "success" || buttonType === "success-gold")
                ? "success"
                : "",
              animation && buttonType === "gold" ? "gold" : "",
            ]}
            exit={"hidden"}
          ></motion.div>
        )}
      </AnimatePresence>
      <div className="button-inner-overlay">
        <span className={small ? "small" : ""}>{children}</span>
      </div>
      {/* <span> {children}</span> */}
    </motion.button>
  );
}

export default Button;
