import { useState } from "react";
import { Background } from "../../../shared/ui/background";
import { ButtonStore } from "../../../shared/ui/game-store-button";
import { Modal } from "../../../shared/ui/modal";
import { NavBar } from "../../../shared/ui/navbar";
import { Player } from "../../../shared/ui/player";
import { PointsArea } from "../../../shared/ui/points-area";
import { Title } from "../../../shared/ui/title";
import { ProfileSettingsButton } from "../../ui/profile-settings-button";
import { ChooseLanguage } from "../profile-language";
import "./profile-view.scss";
import { useAppSelector } from "../../../hooks/hooks";
import { selectUser } from "../../../auth/data-access/store/authSlice";
import { useNavigate } from "react-router-dom";
import {
  selectLanguage,
  selectTranslations,
} from "../../../shared/data-access/store/general/generalSlice";
import { checkTranslationKey } from "../../../utils/translation";
import useLocals from "../../../utils/hooks/use-locals";
import SoundManager from "../../../utils/managers/sound-manager";
import useAmbientSound from "../../../utils/hooks/use-ambient-sound";

export function ViewProfile() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { setValue, getValue } = useLocals();
  const [mute, setMute] = useState<string | null>(getValue("soundsDisabled"));
  const user = useAppSelector(selectUser);
  const lang = useAppSelector(selectLanguage);
  const translations = useAppSelector(selectTranslations);
  useAmbientSound("home", !mute);

  const toggleSounds = () => {
    if (!getValue("soundsDisabled")) {
      setValue("soundsDisabled", "yes");
      setMute("yes");
      for (const [, value] of Object.entries(SoundManager.ambientSounds)) {
        value.muted = true;
      }
    } else {
      setValue("soundsDisabled", null);
      setMute(null);
      for (const [, value] of Object.entries(SoundManager.ambientSounds)) {
        value.muted = false;
      }
    }
  };

  return (
    <div className="main-profile">
      <Background type="dark">
        <NavBar hasAvatar />
        <div className="profile-content">
          <Title>
            {checkTranslationKey(translations[lang]?.Profile, "Profile")}
          </Title>
          <div className="profile-info">
            <div className="profile-image-area">
              <Player
                className="profile-image"
                avatar={user?.character?.avatar || ""}
                hasShadow={false}
              ></Player>
              {/*<button className="profile-camera">*/}
              {/*  <img src="../img/icns/camera.png" alt="" width={20} height={20} />*/}
              {/*</button>*/}
            </div>

            <div className="player-name">
              <h2>{user?.character.nickname}</h2>
            </div>
          </div>
          <div className="points">
            <PointsArea
              count={user?.character?.level?.current}
              icon="level"
              label={checkTranslationKey(translations[lang]?.Level, "Level")}
              itemCount={0}
              total={6}
            ></PointsArea>
            <PointsArea
              count={user?.character?.points}
              icon="diamonds"
              label={checkTranslationKey(
                translations[lang]?.Diamonds,
                "Diamonds"
              )}
              itemCount={1}
              total={6}
              action={() => navigate("/leaderboard")}
            ></PointsArea>
            <PointsArea
              count={user?.character?.position}
              icon="ranking"
              label={checkTranslationKey(
                translations[lang]?.Ranking,
                "Ranking"
              )}
              itemCount={2}
              total={6}
              action={() => navigate("/leaderboard")}
            ></PointsArea>
            <PointsArea
              count={
                (user?.eligibility?.credits?.purchased || 0) +
                (user?.eligibility?.credits?.subscription || 0)
              }
              icon="coins"
              label={checkTranslationKey(translations[lang]?.Coins, "Coins")}
              itemCount={3}
              total={6}
              action={() => navigate("/store")}
            ></PointsArea>
            <PointsArea
              count={user?.character?.achievements?.length}
              icon="achievements"
              label={checkTranslationKey(
                translations[lang]?.Achievements,
                "Achievements"
              )}
              itemCount={4}
              total={6}
              action={() => navigate("/achievements")}
            ></PointsArea>
            <PointsArea
              count={user?.character?.skills?.lives}
              icon="lives"
              label={checkTranslationKey(translations[lang]?.Lives, "Lives")}
              itemCount={5}
              total={6}
            ></PointsArea>
          </div>
          <div className="settings-buttons">
            <ProfileSettingsButton
              onClick={() => {
                navigate("/profile/edit");
              }}
              icon="pencil-fill"
            >
              {checkTranslationKey(
                translations[lang]?.Edit_Profile,
                "Edit Profile"
              )}
            </ProfileSettingsButton>
            {/*<ProfileSettingsButton
              onClick={() => {
                navigate("/achievements");
              }}
              icon="volume-up-fill"
            >
              Achievements
            </ProfileSettingsButton>*/}
            <ProfileSettingsButton
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              icon="global-line"
            >
              {checkTranslationKey(translations[lang]?.Language, "Language")}
            </ProfileSettingsButton>
            <ProfileSettingsButton
              onClick={() => {
                if (user?.eligibility.authentication === "automatic") {
                  navigate("/auth/sign-in", {
                    replace: true,
                    state: "redirectedFromProfile",
                  });
                } else {
                  navigate("/auth/unsubscribe");
                }
              }}
              icon="mail-forbid-line"
            >
              {checkTranslationKey(
                translations[lang]?.Unsubscribe,
                "Unsubscribe"
              )}
            </ProfileSettingsButton>
            <ProfileSettingsButton
              onClick={(e) => {
                e.preventDefault();
                toggleSounds();
              }}
              icon={mute ? "volume-mute-fill" : "volume-up-fill"}
            >
              {checkTranslationKey(translations[lang]?.Sound, "Sound")}
            </ProfileSettingsButton>
          </div>
          <div className="store-button">
            <ButtonStore />
          </div>
        </div>
        <Modal show={showModal} setShow={setShowModal}>
          <ChooseLanguage
            closeModal={() => setShowModal(false)}
          ></ChooseLanguage>
        </Modal>
      </Background>
    </div>
  );
}

export default ViewProfile;
