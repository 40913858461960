import React, { useEffect, useMemo, useState } from "react";
import { Player } from "../../shared/ui/player";
import { Badge } from "../../shared/ui/badge";
import { PrizeToWin } from "../../shared/ui/prize-to-win";
import { Background } from "../../shared/ui/background";
import { NavBar } from "../../shared/ui/navbar";
import { Button } from "../../shared/ui/button";
import { Modal } from "../../shared/ui/modal";
import { BadgeRewards } from "../../shared/ui/badge-reward";
import "./results.scss";
import { checkEligibleToPlay } from "../../home/feature";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  removeNotification,
  selectNotifications,
  selectUser,
} from "../../auth/data-access/store/authSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  selectInit,
  selectLanguage,
  selectTranslations,
} from "../../shared/data-access/store/general/generalSlice";
import CoinsPage from "../../store/ui/store-coins/store-coins";
import { Notification } from "../../shared/interfaces/auth.types";
import { checkTranslationKey } from "../../utils/translation";
import { reset } from "../../shared/data-access/store/game/gameSlices";
import { useGameEvents } from "../../shared/data-access/store/game/hooks/use-game-events";
import {
  useConsumeNotificationsMutation,
  useLazyGetUserQuery,
} from "../../auth/data-access/store/services/auth.service";
import { BadgeModal } from "../../badges/ui/achievement-modal";
import useAmbientSound from "../../utils/hooks/use-ambient-sound";

function Results() {
  const user = useAppSelector(selectUser);
  const notifications = useAppSelector(selectNotifications);
  const [notificationsCopy, setNotificationsCopy] =
    useState<null | Array<Notification>>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const lang = useAppSelector(selectLanguage);
  const init = useAppSelector(selectInit);
  const [showModalCoins, setShowModalCoins] = useState(false);
  const [achievementsReward, setAchievementReward] = useState<null | Array<
    Extract<Notification, { type: "achievement" }>
  >>(null);
  const translations = useAppSelector(selectTranslations);
  const [getUser] = useLazyGetUserQuery();
  const [achievementModal, setAchievementModal] = useState<{
    show: boolean;
    achievementId: null | string;
  }>({
    show: false,
    achievementId: null,
  });
  const [notificationsCopied, setNotificationsCopied] = useState(false);
  const [consumeNotifications] = useConsumeNotificationsMutation();
  useAmbientSound("home");

  const { data: finishGameResponse } = useGameEvents("finish_game");
  const { data: ladderData } = useGameEvents("get_ladder");
  const { data: opponentData } = useGameEvents("get_opponent");

  const prizes = finishGameResponse?.data?.prizes;
  const ladder = ladderData?.data;
  const opponent = opponentData?.data;

  enum status {
    Won = "won",
    Lost = "lost",
    Tie = "tie",
  }

  useEffect(() => {
    if (!ladder) {
      navigate("/");
    } else {
      setNotificationsCopy(notifications);
    }

    return () => {
      getUser();
    };
  }, [navigate]);

  useEffect(() => {
    if (notifications && notifications.length > 0 && !notificationsCopied) {
      setNotificationsCopy(notifications);
      setNotificationsCopied(true);
    }
  }, [notifications]);

  useEffect(() => {
    if (notificationsCopy) {
      const achievementNotifications = notificationsCopy.filter(
        (item) => item.type === "achievement"
      );
      if (achievementNotifications) {
        setAchievementReward(
          achievementNotifications as Array<
            Extract<Notification, { type: "achievement" }>
          >
        );
      }
    }
  }, [notificationsCopy]);

  const currentGameStatus: status | undefined = useMemo(() => {
    if (ladder) {
      const playerWinScore =
        ladder.player.position + (ladder.player.can_advance ? 1 : 0);
      const opponentWinScore =
        ladder.opponent.position + (ladder.opponent.can_advance ? 1 : 0);

      return playerWinScore === opponentWinScore
        ? status.Tie
        : playerWinScore > opponentWinScore
        ? status.Won
        : status.Lost;
    }
  }, [ladder]);

  const handlePlay = () => {
    dispatch(reset());
    navigate("/");
  };

  const handleBuyCredits = () => {
    setShowModalCoins(true);
  };

  const closeAchievementModal = () => {
    setAchievementModal({ show: false, achievementId: null });
  };

  const { disabled, textButton, description, buyCredits } = checkEligibleToPlay(
    {
      eligible: user?.eligibility?.eligible || false,
      canPlay: user?.eligibility?.canPlay || false,
      disableBuyCredits: init?.features?.disableBuyCredits || false,
      subscriptionResourceStatus: user?.eligibility?.subscriptionResourceStatus,
      texts: {
        btnPlay: checkTranslationKey(
          translations[lang]?.Play_again,
          "Play again!"
        ),
        btnPlayBuy: (
          <>
            {checkTranslationKey(
              translations[lang]?.Play_again_for,
              "Play again for"
            )}{" "}
            <img src="/img/icns/coin.svg" alt="" />
          </>
        ),
        descriptionFinishedAll: checkTranslationKey(
          translations[lang]?.You_played_all_the_games_of_the_day,
          "You played all the games of the day! See you again tomorrow!"
        ),
        descriptionBuy: checkTranslationKey(
          translations[lang]?.You_cannot_play_right_now_Check_your_balance,
          "You cannot play right now. Check your balance and try again."
        ),
        descriptionSubscribe: (
          <>
            {checkTranslationKey(
              translations[lang]?.You_cannot_play_right_now_Click,
              "You cannot play right now. Click "
            )}
            <Link to="/auth/subscribe">
              {checkTranslationKey(translations[lang]?.here, "here")}
            </Link>
            {checkTranslationKey(
              translations[lang]?.to_subscribe,
              " to subscribe."
            )}
          </>
        ),
      },
    }
  );

  const selectedAchievement = achievementsReward?.find(
    (achievement) => achievement.id === achievementModal.achievementId
  );

  return (
    <div id="results">
      <Background type="dark">
        <NavBar hasStats hasAvatar />
        <div className="content-results">
          <div className="title-results">
            {/* can use ribbon-red.svg as well */}
            {currentGameStatus === status.Won ? (
              <img src="/img/icns/ribbon-yellow.svg" alt="" />
            ) : (
              <img src="/img/icns/ribbon-red.svg" alt="" />
            )}

            <p>
              {currentGameStatus === status.Won
                ? checkTranslationKey(
                    translations[lang]?.CONGRATULATIONS,
                    "CONGRATULATIONS!"
                  )
                : currentGameStatus === status.Lost
                ? checkTranslationKey(
                    translations[lang]?.NEXT_TIME_BETTER,
                    "NEXT TIME BETTER!"
                  )
                : checkTranslationKey(
                    translations[lang]?.NEXT_TIME_BETTER,
                    "NEXT TIME BETTER!"
                  )}
            </p>
          </div>
          <div className="players">
            <div className="player-wrapper">
              <Player
                winner={currentGameStatus === status.Won}
                bottom={
                  <Badge type="primary">
                    {user?.character?.level?.current}
                  </Badge>
                }
                avatar={user?.character?.avatar || ""}
                name={user?.character?.nickname || ""}
              ></Player>
            </div>
            <div className="player-wrapper">
              <Player
                winner={currentGameStatus === status.Lost}
                bottom={
                  <Badge type="primary">{opponent?.opponent?.level}</Badge>
                }
                avatar={opponent?.opponent?.avatar || ""}
                name={opponent?.opponent?.nickname}
              ></Player>
            </div>
          </div>
          <p className="title-rewards">
            {checkTranslationKey(
              translations[lang]?.You_ended_up_with,
              "You ended up with:"
            )}
          </p>
          <div className="rewards">
            <BadgeRewards />
            <div className="list-rewards">
              {prizes && (
                <>
                  {prizes.points > 0 && (
                    <div className="item">
                      <div className="img">
                        <img src="/img/icns/diamonds.svg" alt="" />
                      </div>
                      <p>{prizes.points}</p>
                    </div>
                  )}
                  {prizes.lives > 0 && (
                    <div className="item">
                      <div className="img">
                        <img src="/img/icns/lives.svg" alt="" />
                      </div>
                      <p>{prizes.lives}</p>
                    </div>
                  )}

                  {prizes.prize && (
                    <div className="item">
                      <div className="img">
                        <img src={prizes.prize.image} alt="" />
                      </div>
                      <p>{prizes.prize.name[lang]}</p>
                    </div>
                  )}
                </>
              )}

              {achievementsReward?.map((achievementReward) => (
                <div className="item" key={achievementReward.id}>
                  <div
                    className="img"
                    onClick={() =>
                      setAchievementModal({
                        show: true,
                        achievementId: achievementReward.id,
                      })
                    }
                  >
                    <img
                      src={achievementReward.image}
                      alt={achievementReward.text?.title[lang] || ""}
                    />
                    <div className="information">
                      <img
                        src="/img/icns/information-icon.svg"
                        alt={achievementReward.text?.description[lang]}
                      />
                    </div>
                  </div>
                  <p>{achievementReward.text?.title[lang]}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="btn-container">
            <Button
              buttonType="success"
              disabled={disabled}
              onClick={buyCredits ? handleBuyCredits : handlePlay}
            >
              {textButton}
            </Button>
            {description && <p>{description}</p>}
          </div>
        </div>

        <Modal
          setShow={setShowModalCoins}
          show={showModalCoins}
          hideCloseButton
        >
          <div className="modal-buy-coins">
            <CoinsPage
              title={checkTranslationKey(
                translations[lang]?.You_run_out_of_coins,
                "You run out of coins!"
              )}
              subtitle={checkTranslationKey(
                translations[lang]?.Buy_more_coins_now_and_keep_on_playing,
                "Buy more coins now and keep on playing!"
              )}
              confirmCallback={() => setShowModalCoins(false)}
            />
          </div>
        </Modal>
      </Background>
      <div className="prizes-win">
        <PrizeToWin />
      </div>

      {selectedAchievement && (
        <Modal
          show={achievementModal.show}
          setShow={closeAchievementModal}
          hideCloseButton
          disableClickOutside={true}
        >
          <BadgeModal
            badge={selectedAchievement.image}
            title={selectedAchievement.text?.title[lang]}
            buttonText={selectedAchievement.button_text[lang]}
            buttonClick={() => {
              closeAchievementModal();
              consumeNotifications({ id: selectedAchievement.id });
              dispatch(removeNotification({ id: selectedAchievement.id }));
            }}
          >
            {selectedAchievement.text?.description[lang]}
          </BadgeModal>
        </Modal>
      )}
    </div>
  );
}

export default Results;
